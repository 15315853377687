import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField } from "@mui/material";
import { template } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { useCreateDoc } from "../hooks";
import { WithServerFields } from "../stores";

interface Props {
  sender: WithServerFields<Sender>;
}
function CreateBuzzerForm({ sender }: Props) {
  const [reference, setReference] = useState("");
  const [error, setError] = useState("");
  const {
    create,
    loading,
    error: createBuzzerError,
  } = useCreateDoc<Buzzer>("buzzers");

  const {
    create: createMessage,
    loading: loadingMessage,
    error: createMessageError,
  } = useCreateDoc<Message>("messages");
  const [_, setLocation] = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (createMessageError)
      enqueueSnackbar({ message: createMessageError, variant: "error" });
    if (createBuzzerError)
      enqueueSnackbar({ message: createBuzzerError, variant: "error" });
  }, [createMessageError, createBuzzerError]);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!reference) {
      setError("Please enter a reference");
      return;
    }

    setError("");
    const buzzerId = await create({
      reference,
      status: "ACTIVE",
      senderId: sender.id,
      users: [],
      senderName: sender.name,
      createdBy: sender.createdBy,
    });

    if (!buzzerId) return;

    const compiled = template(
      sender.messageTemplates.subscribeConfirmation?.body ||
        `All set 💪! You will receive a notification from ${sender.name} as soon as it's ready. Your reference is ${reference}.`
    );
    const body = compiled({
      sender,
      buzzer: { reference },
    });

    const messageId = await createMessage({
      body,
      imageUrl: sender.messageTemplates.subscribeConfirmation?.imageUrl || "",
      title: sender?.name,
      buzzerId,
      senderId: sender.id,
      createdBy: sender.createdBy,
      type: "subscribeConfirmation",
    });

    if (!messageId) return;

    setReference("");
    setLocation(`/qr/${buzzerId}/`);
  };

  return (
    <form
      onSubmit={submitForm}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="flex-start"
        flexWrap="wrap"
        justifyContent="center"
        useFlexGap
      >
        <Box>
          <TextField
            label="Customer Reference"
            placeholder="Ralphie"
            value={reference}
            onChange={(e) => {
              setError("");
              setReference(e.target.value);
            }}
            disabled={loading || loadingMessage}
            error={!!error}
            helperText={error}
            size="small"
            margin="none"
          />
        </Box>
        <Box>
          <LoadingButton
            type="submit"
            loading={loading || loadingMessage}
            variant="gradient"
            sx={{ fontWeight: "400" }}
          >
            Create Buzzer!
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
}

export default CreateBuzzerForm;
