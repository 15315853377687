import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { useLocation, useSearch } from "wouter";
import { useUserStore } from "../stores";

function LoginDialog() {
  const search = useSearch();
  const emailSignIn = useUserStore((state) => state.emailSignIn);
  const [location, setLocation] = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const user = useUserStore((state) => state.user);

  const handleClose = () => {
    setLocation(!user ? "~/" : location);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await emailSignIn(email, password);
      setLocation("/senders");
    } catch (e: any) {
      console.log(e);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={search.includes("login=1")}
      onClose={handleClose}
      keepMounted={false}
    >
      <DialogTitle textAlign="center">Log in</DialogTitle>
      <DialogContent sx={{ width: 300 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            fullWidth
            type="email"
            autoComplete="email"
          />
          <TextField
            label="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            error={!!error}
            helperText={error}
            disabled={loading}
            autoComplete="current-password"
            fullWidth
          />
          <LoadingButton type="submit" loading={loading} fullWidth>
            Login
          </LoadingButton>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default LoginDialog;
