import { Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useRef, useState } from "react";

interface Props {
  label: string;
  value?: string;
  alt?: string;
  onChange: (file?: File | null) => void;
}

function ImageField({ label, value, alt = "", onChange }: Props) {
  const ref = useRef<HTMLInputElement>();
  const [file, setFile] = useState<File | null>();

  const handleChange = () => {
    const newFile = ref?.current?.files?.item(0);
    setFile(newFile);
    onChange(newFile);
  };
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel shrink variant="outlined" sx={{ background: "white", px: 1 }}>
        {label}
      </InputLabel>

      <OutlinedInput
        type="file"
        inputRef={ref}
        sx={{ mb: 2 }}
        inputProps={{ accept: "image/*" }}
        onChange={handleChange}
        endAdornment={
          (value || file) && (
            <Box sx={{ maxWidth: 75, maxHeight: 75, my: 1.5 }}>
              <img
                src={file ? URL.createObjectURL(file) : value}
                style={{ maxWidth: "100%" }}
                alt={alt}
              />
            </Box>
          )
        }
      />
    </FormControl>
  );
}

export default ImageField;
