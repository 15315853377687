import {
  Facebook,
  Instagram,
  Twitter,
  VolumeUp,
  WhatsApp,
} from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "wouter";
import {
  useCreateDoc,
  useNow,
  useSubscribe,
  useSubscribeOne,
  useWakeLock,
} from "../../hooks";
import { useUserStore } from "../../stores";
import ConnectionStatus from "../ConnectionStatus";
import MessageListUser from "../MessageListUser";
import NotificationDrawer from "./NotificationDrawer";

function Buzzer() {
  const { buzzerId = "" } = useParams();
  const [user, userProfile, anonymousSignIn] = useUserStore((state) => [
    state.user,
    state.userProfile,
    state.anonymousSignIn,
  ]);

  const now = useNow(60 * 60);

  const [buzzers] = useSubscribe<Buzzer>("buzzers", [
    ["users", "array-contains", user?.uid],
    ["createdAt", ">", dayjs(now).subtract(4, "hours").toDate()],
  ]);
  const buzzerIds = buzzers.map((b) => b.id);
  const [currentBuzzer, _l, buzzerError] = useSubscribeOne<Buzzer>(
    "buzzers",
    buzzerId
  );
  const [subscribers, loadingSubscribers, subscribersError] =
    useSubscribe<Subscriber>("subscribers", [["userId", "==", user?.uid]]);
  const isSubscribed = subscribers.some((s) => s.buzzerId === buzzerId);

  const { create } = useCreateDoc<Subscriber>("subscribers");
  const [sender] = useSubscribeOne<Sender>("senders", currentBuzzer?.senderId);

  useEffect(() => {
    if (user === null) {
      anonymousSignIn();
    }
  }, [user]);

  useEffect(() => {
    if (
      !loadingSubscribers &&
      !isSubscribed &&
      user?.uid &&
      currentBuzzer?.id
    ) {
      create({
        createdBy: user.uid,
        userId: user.uid,
        buzzerId: currentBuzzer.id,
        senderId: currentBuzzer.senderId,
      });
    }
  }, [loadingSubscribers, isSubscribed, user?.uid, currentBuzzer?.id]);
  const { wakeLock, wakeLockSupport, enableWakeLock } = useWakeLock();
  const shouldEnableWakeLock = wakeLockSupport && !wakeLock;
  const [_, setLocation] = useLocation();
  const [userHasBeenActive, setUserHasBeenActive] = useState(
    // @ts-ignore
    navigator?.userActivation?.hasBeenActive || false
  );
  return (
    <Container
      sx={{
        pb: 12,
        pt: 2,
        textAlign: "center",
        background: `url(${sender?.backgroundUrl})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Backdrop
        open={!isSubscribed && !!buzzerId}
        sx={{ zIndex: 999, backgroundColor: "rgba(255,255,255,0.9)" }}
      >
        <Box sx={{ m: 1 }}>
          <CircularProgress />
          <Typography variant="h6">
            1 sec, we're setting up your Smart Buzzer!
          </Typography>
        </Box>
      </Backdrop>
      <Box>
        <Box>
          {sender?.logoUrl ? (
            <img
              src={sender.logoUrl}
              style={{ maxWidth: 300, maxHeight: 100 }}
              alt={sender.name}
            />
          ) : (
            <Typography
              className="gradientText"
              variant="h3"
              fontWeight="800"
              display="inline"
            >
              {sender?.name || "Smart Buzzer"}
            </Typography>
          )}
        </Box>
        <ConnectionStatus connected={!(buzzerError && subscribersError)} />
        <Box>
          {sender?.twitter && (
            <IconButton onClick={() => window.open(sender.twitter)}>
              <Twitter />
            </IconButton>
          )}
          {sender?.instagram && (
            <IconButton onClick={() => window.open(sender.instagram)}>
              <Instagram />
            </IconButton>
          )}
          {sender?.facebook && (
            <IconButton onClick={() => window.open(sender.facebook)}>
              <Facebook />
            </IconButton>
          )}
          {/* {sender?.tiktok && (
                    <IconButton onClick={() => window.open(sender.tiktok)}>
                      <TikTok />
                    </IconButton>
                  )} */}
        </Box>
        {currentBuzzer && buzzers.length > 1 && (
          <Box>
            <Typography variant="caption">Other buzzers:</Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {buzzers
                .filter((b) => b.id !== currentBuzzer.id)
                .map((buzzer) => (
                  <div key={buzzer.id}>
                    <Chip
                      size="small"
                      label={`${buzzer.senderName} - ${buzzer.reference}`}
                      onClick={(e) => setLocation(`/buzzer/${buzzer.id}`)}
                      sx={{ mb: 1 }}
                    />
                  </div>
                ))}
            </Stack>
          </Box>
        )}

        {!userProfile?.whatsAppActive && (
          <Alert
            sx={{ my: 1 }}
            severity="warning"
            icon={<WhatsApp />}
            action={
              <Button
                color="warning"
                onClick={() => {
                  wakeLockSupport && enableWakeLock();
                  window.open(
                    `https://wa.me/12137725411?text=${userProfile?.id}`,
                    "_blank"
                  );
                }}
                size="small"
              >
                Enable
              </Button>
            }
          >
            Enable WhatsApp
          </Alert>
        )}

        {userProfile?.whatsAppActive &&
          (shouldEnableWakeLock || !userHasBeenActive) && (
            <Alert
              sx={{ my: 1 }}
              severity="warning"
              icon={<VolumeUp />}
              action={
                <Button
                  color="warning"
                  onClick={() => {
                    setUserHasBeenActive(true);
                    wakeLockSupport && enableWakeLock();
                  }}
                  size="small"
                >
                  Enable
                </Button>
              }
            >
              Enable sound
            </Alert>
          )}
        <MessageListUser buzzerIds={buzzerIds} />
      </Box>
      <NotificationDrawer />
    </Container>
  );
}

export default Buzzer;
