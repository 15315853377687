import SmartphoneIcon from "@mui/icons-material/Smartphone";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from "@mui/material";
import { useWakeLock } from "../../hooks";

function WakeLockButton() {
  const { wakeLock, wakeLockSupport, enableWakeLock, disableWakeLock } =
    useWakeLock();

  if (!wakeLockSupport) return null;
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => (wakeLock ? disableWakeLock() : enableWakeLock())}
        >
          <ListItemIcon>
            <SmartphoneIcon />
          </ListItemIcon>
          <ListItemText
            primary={"Phone-as-buzzer"}
            secondary="Prevent screen lock and show flashing notifications"
          />
          <Switch edge="end" checked={!!wakeLock} />
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default WakeLockButton;
