import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCE4FTuw1x_CdPL0-dK6q22bOd8NBHA3sw",
  authDomain: "smart-buzzer-123.firebaseapp.com",
  projectId: "smart-buzzer-123",
  storageBucket: "smart-buzzer-123.appspot.com",
  messagingSenderId: "693115869281",
  appId: "1:693115869281:web:8027123a2b2ec0cc217220",
};

export const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const messaging = getMessaging(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

const vapidKey =
  "BKCkpIhW6jpSzre4bjckT2P5GVkPe2rrd54j8_cNZKeZxFh8i1Imxr-XSIdKGCO9pI2irtohtwCu3dAxusu5ERo";

if (process.env.NODE_ENV === "development") {
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
}

export { auth, db, messaging, storage, vapidKey };
