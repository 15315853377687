import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from "@mui/material";
import { useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../../stores";

function WhatsAppButton() {
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = useState(false);
  const [userProfile, updateUserProfile] = useUserStore(
    useShallow((state) => [state.userProfile, state.updateUserProfile])
  );

  const close = () => {
    setOpen(false);
    setUpdating(false);
  };

  const unsubscribe = async () => {
    await updateUserProfile({ whatsApp: "", whatsAppActive: false });
    close();
  };

  const handleClick = async () => {
    if (userProfile?.whatsApp) {
      setUpdating(true);
      setOpen(true);
    } else {
      window.open(`https://wa.me/12137725411?text=${userProfile?.id}`);
    }
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            {updating ? <CircularProgress size={24} /> : <WhatsAppIcon />}
          </ListItemIcon>
          <ListItemText
            primary={"WhatsApp"}
            secondary="Send us the prefilled message and we'll notify you on WhatsApp!"
          />
          <Switch
            edge="end"
            checked={!!userProfile?.whatsAppActive}
            disabled={updating}
          />
        </ListItemButton>
      </ListItem>
      <Dialog open={open} onClose={close}>
        <DialogTitle>
          Are you sure you want to disable WhatsApp notifications?
        </DialogTitle>
        <DialogActions>
          <Button size="small" variant="outlined" onClick={close}>
            No thanks
          </Button>
          <Button size="small" variant="gradient" onClick={unsubscribe}>
            Yes please
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WhatsAppButton;
