import { LoadingButton } from "@mui/lab";
import { Box, FormGroup, TextField } from "@mui/material";
import { useState } from "react";
import { useLocation } from "wouter";
import { useCreateDoc } from "../hooks";
import { useUserStore } from "../stores";

interface Props {
  label?: string;
}
function CreateSenderForm({ label = "Create your first buzzer" }: Props) {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [_, setLocation] = useLocation();
  const { create, loading } = useCreateDoc<Sender>("senders");
  const [user, anonymousSignIn] = useUserStore((state) => [
    state.user,
    state.anonymousSignIn,
  ]);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name) {
      setError(true);
      return;
    }

    let createdBy = user?.uid;
    if (!createdBy) {
      const newUser = await anonymousSignIn();
      createdBy = newUser.uid;
    }

    if (!createdBy) {
      setError(true);
      return;
    }

    const id = await create({
      name,
      createdBy,
      messageTemplates: {},
    });
    setLocation(`/dashboard/${id}/`);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
      <form onSubmit={submitForm}>
        <FormGroup>
          <TextField
            // label="Your restaurant name"
            placeholder="Your restaurant name"
            value={name}
            onChange={(e) => {
              setError(!Boolean(e.target.value));
              setName(e.target.value);
            }}
            error={error}
            fullWidth
            inputProps={{ style: { fontSize: 23 } }}
          />
          <LoadingButton
            type="submit"
            loading={loading}
            variant="gradient"
            sx={{ fontSize: (t) => t.typography.h6 }}
          >
            {label}
          </LoadingButton>
        </FormGroup>
      </form>
    </Box>
  );
}

export default CreateSenderForm;
