import { Box, Button, Stack, Typography } from "@mui/material";
import { useLocation } from "wouter";
import { useUserStore } from "../stores";

interface Props {
  items: ("HOME" | "LOGIN" | "SETTINGS" | "SENDERS" | "SIGNUP")[];
}

function MenuBar({ items }: Props) {
  const [user, logOut] = useUserStore((state) => [state.user, state.logOut]);
  const [location, setLocation] = useLocation();
  return (
    <>
      <Box
        sx={{
          textAlign: "right",
          my: 1,
        }}
      >
        {user && !user.isAnonymous && (
          <Typography sx={{ mr: 1 }}>Hi {user.email}! </Typography>
        )}
        <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
          {items.includes("HOME") && (
            <Button variant="text" onClick={() => setLocation("~/")}>
              Home
            </Button>
          )}
          {items.includes("SENDERS") && !!user && (
            <Button variant="text" onClick={() => setLocation("~/senders")}>
              Buzzers
            </Button>
          )}
          {items.includes("SETTINGS") && (
            <Button variant="text" onClick={() => setLocation("?settings=1")}>
              Settings
            </Button>
          )}

          {items.includes("LOGIN") && (
            <Box>
              {user?.email ? (
                <>
                  <Button
                    variant="text"
                    onClick={async () => {
                      setLocation("~/");
                      await logOut();
                    }}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <Button variant="text" onClick={() => setLocation("?login=1")}>
                  Login
                </Button>
              )}
            </Box>
          )}
          {(!user || user.isAnonymous) && items.includes("SIGNUP") && (
            <Button
              className="gradient"
              onClick={() => setLocation("?signup=1")}
            >
              Sign up
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
}

export default MenuBar;
