import {
  DocumentSnapshot,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { isArray } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { db } from "./firebaseConfig";
import { useUserStore } from "./stores";

export type WithServerFields<T> = T & { createdAt: Date; id: string };

type Filter<T> = [
  Extract<keyof WithServerFields<T>, string>,
  "==" | "!=" | "<" | ">" | "in" | "array-contains",
  string | number | string[] | undefined | Date
];

type Order<T> = [Extract<keyof WithServerFields<T>, string>, "asc" | "desc"];

const snapToData = <T>(snap: DocumentSnapshot) => {
  const data = snap.data({ serverTimestamps: "estimate" });
  if (!data) return;
  return {
    ...data,
    id: snap.id,
    createdAt: data.createdAt.toDate(),
  } as WithServerFields<T>;
};

export const useSubscribeOne = <T>(colName: string, path?: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState<WithServerFields<T>>();
  const { user } = useUserStore();

  useEffect(() => {
    if (!path || !user?.uid) return;
    const col = collection(db, colName);
    const ref = doc(col, path);
    return onSnapshot(
      ref,
      (snap) => {
        const data = snapToData<T>(snap);
        if (!data) return;
        setData(data);
        setLoading(false);
      },
      (e) => {
        setError(e.message);
        setLoading(false);
      }
    );
  }, [colName, path, user?.uid]);

  return [data, loading, error] as [typeof data, typeof loading, typeof error];
};

export const useSubscribe = <T>(
  colName: string,
  filters: Filter<T>[],
  order: Order<T> = ["createdAt", "desc"]
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState<WithServerFields<T>[]>([]);
  const { user } = useUserStore();

  useEffect(() => {
    if (
      filters.some(
        (f) => f[2] === undefined || (isArray(f[2]) && f[2].length === 0)
      ) ||
      !user?.uid
    )
      return;

    const col = collection(db, colName);
    const q = query(col, ...filters.map((f) => where(...f)), orderBy(...order));

    return onSnapshot(
      q,
      (snap) => {
        const docs: WithServerFields<T>[] = [];
        snap.forEach((doc) => {
          const data = snapToData<T>(doc);
          if (!data) return;
          docs.push(data);
        });
        setData(docs);
        setLoading(false);
      },
      (e) => {
        setError(e.message);
        setLoading(false);
      }
    );
  }, [colName, user?.uid, filters.flat(Infinity).join(""), order.join("")]);

  return [data, loading, error] as [typeof data, typeof loading, typeof error];
};

export const useUpdateDoc = <T>(colName: string, path: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const col = collection(db, colName);
  const update = async (data: Partial<T>) => {
    setLoading(true);
    const ref = doc(col, path);
    try {
      await setDoc(ref, data, { merge: true });
      return ref.id;
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return { update, loading, error };
};

export const useCreateDoc = <T extends { [x: string]: any }>(
  colName: string
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const create = useCallback(
    async (data: T) => {
      setLoading(true);
      setError("");
      const col = collection(db, colName);
      const ref = doc(col);
      try {
        await setDoc(
          ref,
          { ...data, createdAt: serverTimestamp() },
          { merge: true }
        );
        return ref.id;
      } catch (e: any) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [colName]
  );

  return { create, loading, error };
};

export const useNow = (seconds = 10) => {
  const [now, setNow] = useState(new Date(Date.now()));
  useEffect(() => {
    const timer = setInterval(() => {
      setNow(new Date(Date.now()));
    }, 1000 * seconds);

    return () => clearInterval(timer);
  }, [seconds]);

  return now;
};

export const useDocumentVisible = (documentElement = document) => {
  const [documentVisible, setDocumentVisible] = useState(
    documentElement.visibilityState
  );

  useEffect(() => {
    const handleVisibilityChange = () =>
      setDocumentVisible(documentElement.visibilityState);

    documentElement.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );

    return () =>
      documentElement.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
  }, [documentElement]);

  return documentVisible === "visible";
};

export const useAlarm = () => {
  const alarm = useMemo(
    () => new Audio(`${process.env.PUBLIC_URL}/alarm.mp3`),
    []
  );
  alarm.preload = "auto";
  alarm.loop = true;

  const [int, setInt] = useState<NodeJS.Timer>();

  const play = useCallback(() => {
    if (int) return;
    if ("vibrate" in navigator)
      navigator.vibrate([
        200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200,
      ]);

    const interval = setInterval(() => {
      alarm
        .play()
        .then(() => {
          clearInterval(interval);
        })
        .catch((error) => {
          console.info("User has not interacted with document yet.");
        });
    }, 500);
    setInt(interval);
  }, [alarm, int]);

  const pause = useCallback(() => {
    clearInterval(int);
    alarm.pause();
    if ("vibrate" in navigator) navigator.vibrate(0);
  }, [alarm, int]);

  return { play, pause };
};

export const useWakeLock = () => {
  //@ts-ignore
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel>();
  const wakeLockSupport = "wakeLock" in navigator;

  const enableWakeLock = useCallback(async () => {
    //@ts-ignore
    const newWakeLock = await navigator.wakeLock.request("screen");
    setWakeLock(newWakeLock);
  }, []);

  const disableWakeLock = useCallback(async () => {
    wakeLock?.release();
    setWakeLock(undefined);
  }, [wakeLock]);

  useEffect(() => {
    if (!wakeLockSupport) return;

    document.addEventListener("visibilitychange", async () => {
      if (document.visibilityState === "visible") {
        enableWakeLock();
      }
    });

    try {
      enableWakeLock();
    } catch (e) {
      // nothing. iPhone doesn't allow this to be enabled without user interaction.
    }
  }, [wakeLockSupport, enableWakeLock]);

  return { wakeLock, wakeLockSupport, enableWakeLock, disableWakeLock };
};
