import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Box, Stack, Typography } from "@mui/material";
import { useSubscribe } from "../hooks";
import Message from "./Message";

interface Props {
  buzzerIds: string[];
}

function MessageListUser({ buzzerIds }: Props) {
  const [messages] = useSubscribe<Message>("messages", [
    ["buzzerId", "in", buzzerIds],
  ]);

  return (
    <>
      {messages.length === 0 && (
        <Box sx={{ position: "relative", pt: 10 }}>
          <div className="pulsating-circle" />
          <Typography variant="h6" sx={{ my: 6 }} color="GrayText">
            You will be notified about any of your buzzers here :)
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mx: 2,
              mt: 1,
              mb: 0,
              color: "GrayText",
              fontWeight: "bold",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <VolumeUpIcon sx={{ mr: 1 }} color="inherit" />
            <Typography fontWeight="bold">Please enable your sound!</Typography>
          </Box>
        </Box>
      )}
      {/* We currently only pass active buzzers, which means that activation messages
      will not be shown after the buzzer has closed. Might need to change in the future! */}
      <Stack spacing={2} sx={{ mt: 4 }}>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </Stack>
    </>
  );
}

export default MessageListUser;
