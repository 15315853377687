import {
  EmailAuthProvider,
  User,
  createUserWithEmailAndPassword,
  linkWithCredential,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  collection,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { create } from "zustand";
import { auth, db } from "./firebaseConfig";

export type WithServerFields<T> = T & { createdAt: Date; id: string };

interface UserStore {
  initialUserLoading: boolean;
  user?: User | null;
  userProfile?: WithServerFields<UserProfile>;
  updateUserProfile: (userProfile: Partial<UserProfile>) => Promise<void>;
  emailSignIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  anonymousSignIn: () => Promise<User>;
  logOut: () => Promise<void>;
}

export const useUserStore = create<UserStore>((set, get) => {
  const userProfileCol = collection(db, "userProfiles");

  const subscribeUserProfile = (uid: string) => {
    const userProfile = doc(userProfileCol, uid);
    onSnapshot(userProfile, async (res) => {
      if (!res.exists()) {
        await setDoc(userProfile, { createdAt: serverTimestamp() });
        return;
      }
      if (res.data()) {
        const data = res.data() as WithServerFields<UserProfile>;
        set({ userProfile: { ...data, id: res.id } });
      }
    });
  };

  onAuthStateChanged(auth, (user) => {
    if (!user) {
      set({ user, userProfile: undefined, initialUserLoading: false });
      return;
    }
    set({ user, initialUserLoading: false });
    subscribeUserProfile(user.uid);
  });

  const anonymousSignIn = async () => {
    const { user } = await signInAnonymously(auth);
    return user;
  };
  return {
    initialUserLoading: true,
    anonymousSignIn,
    updateUserProfile: async (data) => {
      const userProfile = doc(userProfileCol, get().userProfile?.id);
      await updateDoc(userProfile, data);
    },
    emailSignIn: async (email, password) => {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      subscribeUserProfile(user.uid);
    },
    signUp: async (email, password) => {
      let existingUser = get().user;
      if (existingUser?.isAnonymous) {
        const cred = EmailAuthProvider.credential(email, password);
        const { user } = await linkWithCredential(existingUser, cred);
        subscribeUserProfile(user.uid);
      } else {
        const { user } = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        subscribeUserProfile(user.uid);
      }
    },
    logOut: async () => {
      signOut(auth);
    },
  };
});
