import { Typography } from "@mui/material";
import { Route, Switch } from "wouter";
import Dashboard from "./components/Dashboard";
import Landing from "./components/Landing";
import LoginDialog from "./components/LoginDialog";
import Senders from "./components/Senders";
import SignupDialog from "./components/SignupDialog";
import Buzzer from "./components/buzzer/Buzzer";

function App() {
  return (
    <>
      <LoginDialog />
      <SignupDialog />
      <Switch>
        <Route path={"/dashboard/:senderId"} component={Dashboard} nest />
        <Route path={"buzzer/:buzzerId"} component={Buzzer} />
        <Route path={"buzzers"} component={Buzzer} />
        <Route path={"senders"} component={Senders} />
        <Route component={Landing} />
      </Switch>
      <Typography
        variant="caption"
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          padding: 2,
          background: (t) => t.palette.background.default,
          borderTop: (t) => `1px solid ${t.palette.divider}`,
          textAlign: "center",
        }}
      >
        Smart Buzzer © 2024 | Made with ❤️ by{" "}
        <a href="mailto:ralph@smartbuzzer.app">Ralph</a>
      </Typography>
    </>
  );
}

export default App;
