import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "wouter";
import { useSubscribeOne } from "../hooks";

function QrDialog() {
  const { buzzerId } = useParams();
  const [location, setLocation] = useLocation();
  const [image, setImage] = useState("");
  const buzzerUrl = `https://${window.location.host}/buzzer/${buzzerId}/`;
  useEffect(() => {
    QRCode.toDataURL(`https://${window.location.host}/buzzer/${buzzerId}/`, {
      width: 300,
      margin: 0,
    }).then((res) => setImage(res));
  }, []);

  const [buzzer, loading] = useSubscribeOne<Buzzer>("buzzers", buzzerId);

  const handleClose = () => {
    setLocation(`/`);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle textAlign="center">
        Get your Smart Buzzer!
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading || buzzer?.users.length === 0 ? (
            <>
              <CircularProgress size={10} sx={{ mr: 1 }} />
              <Typography variant="caption">waiting for customer</Typography>
            </>
          ) : (
            <>
              <CheckCircle fontSize="inherit" sx={{ mr: 1 }} color="success" />
              <Typography variant="caption">
                customer can be notified
              </Typography>
            </>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ width: 300 }}>
        <img src={image} style={{ width: "100%" }} />
        <Typography variant="caption" sx={{ wordBreak: "break-all" }}>
          {buzzerUrl}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default QrDialog;
