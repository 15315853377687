import { Box, Divider, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useSubscribe } from "../hooks";
import { useUserStore } from "../stores";

interface Props {
  buzzerId: string;
}

function MessageList({ buzzerId }: Props) {
  const [userProfile] = useUserStore((state) => [state.userProfile]);
  const [messages, loading] = useSubscribe<Message>("messages", [
    ["buzzerId", "==", buzzerId],
    ["createdBy", "==", userProfile?.id],
  ]);

  return (
    <Stack spacing={2} divider={<Divider />} sx={{ mt: 4 }}>
      {messages.map((message, index) => (
        <Box key={index}>
          <Typography variant="caption" color="GrayText">
            {message.createdAt.toLocaleString()}
          </Typography>
          <Typography>{message.body}</Typography>
        </Box>
      ))}
    </Stack>
  );
}

export default memo(MessageList);
