import { NotificationsOff } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { dayjs } from "../config";
import {
  useAlarm,
  useDocumentVisible,
  useNow,
  useSubscribeOne,
} from "../hooks";
import { WithServerFields } from "../stores";

interface Props {
  message: WithServerFields<Message>;
}

function Message({
  message: { body, createdAt, senderId, title, important, id, imageUrl },
}: Props) {
  const now = useNow();
  const [sender] = useSubscribeOne<Sender>("senders", senderId);
  const tabVisible = useDocumentVisible();
  const [read, setRead] = useState(
    localStorage.getItem("readMessages")?.split(",")?.includes(id)
  );

  body = body.replace(/(<([^>]+)>)/gi, "");
  body = body.replace(/\*(\S.*?\S)\*/gi, "<b>$1</b>");
  const urlRegex = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
    "g"
  );

  const urlMatches = body.match(urlRegex) || [];
  for (const match of urlMatches) {
    let href = match;
    if (!match.startsWith("http")) {
      href = `https://${href}`;
    }

    body = body.replace(
      match,
      `<a href="${href}" target="_blank">${match}</a>`
    );
  }

  const alarm = useAlarm();

  const markRead = () => {
    setRead(true);
    alarm.pause();
    const readMessages = localStorage.getItem("readMessages")?.split(",") || [];
    readMessages.push(id);
    localStorage.setItem("readMessages", readMessages.join(","));
  };

  useEffect(() => {
    if (important && !read && tabVisible) {
      alarm.play();
    } else {
      alarm.pause();
    }
    return alarm.pause;
  }, [read, tabVisible, important]);

  return (
    <Card
      className={important && !read ? "flashing" : ""}
      sx={{ textAlign: "left", borderRadius: 3 }}
    >
      <CardHeader
        avatar={
          <Avatar className="gradient" alt={sender?.name} src={sender?.logoUrl}>
            {sender?.name.slice(0, 2).toUpperCase()}
          </Avatar>
        }
        title={title}
        subheader={dayjs(createdAt).from(now)}
        titleTypographyProps={{
          sx: {
            fontWeight: "bold",
          },
        }}
        action={
          important &&
          !read && (
            <IconButton onClick={markRead}>
              <NotificationsOff color="disabled" />
            </IconButton>
          )
        }
      />
      {imageUrl && (
        <CardMedia component="img" height="194" image={imageUrl} alt={title} />
      )}
      <CardContent>
        <Typography component="div">
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Message;
