import { Chip, Stack, Typography } from "@mui/material";
import { template } from "lodash";
import { useState } from "react";
import { WithServerFields, useCreateDoc, useUpdateDoc } from "../hooks";

interface Props {
  buzzer: WithServerFields<Buzzer>;
  sender: WithServerFields<Sender>;
}

function ExpectedWaitTime({ buzzer, sender }: Props) {
  const [selected, setSelected] = useState<string>(
    buzzer.expectedWaitTime || ""
  );
  const { update: updateBuzzer } = useUpdateDoc<Buzzer>("buzzers", buzzer.id);
  const { create: createMessage } = useCreateDoc<Message>("messages");

  const handleClick = async (time: string) => {
    await updateBuzzer({ expectedWaitTime: time });
    setSelected(time);

    // not nice, refactor
    buzzer.expectedWaitTime = time;
    const compiled = template(
      sender.messageTemplates.expectedWaitTime?.body ||
        `Your order will be ready in ${buzzer.expectedWaitTime} minutes.`
    );
    const body = compiled({
      sender,
      buzzer,
    });

    await createMessage({
      body,
      imageUrl: sender.messageTemplates.expectedWaitTime?.imageUrl || "",
      title: sender?.name,
      buzzerId: buzzer.id,
      senderId: sender.id,
      createdBy: sender.createdBy,
    });
  };

  const waitTimes = ["< 5", "5-10", "10-15", "15-20"];
  return (
    <>
      <Typography variant="caption">Expected wait time (minutes):</Typography>
      <br />
      <Stack direction="row" spacing={1}>
        {waitTimes.map((t) => (
          <Chip
            onClick={() => handleClick(t)}
            label={t}
            key={t}
            size="small"
            variant={selected === t ? "filled" : "outlined"}
            disabled={buzzer.status !== "ACTIVE"}
          />
        ))}
      </Stack>
    </>
  );
}

export default ExpectedWaitTime;
