import NotificationsIcon from "@mui/icons-material/Notifications";
import { Divider, IconButton, Stack, SwipeableDrawer } from "@mui/material";
import { useState } from "react";
import PushNotificationButton from "./PushNotificationButton";
import VoiceCallButton from "./VoiceCallButton";
import WakeLockButton from "./WakeLockButton";
import WhatsAppButton from "./WhatsAppButton";

function NotificationDrawer() {
  const [settingsOpen, setSettingsOpen] = useState(false);

  return (
    <>
      <IconButton
        sx={{
          position: "fixed",
          bottom: 66,
          right: 12,
          zIndex: 99,
        }}
        className="gradient"
        onClick={() => setSettingsOpen(true)}
      >
        <NotificationsIcon sx={{ color: "white" }} />
      </IconButton>
      <SwipeableDrawer
        anchor="bottom"
        open={settingsOpen}
        onOpen={() => setSettingsOpen(true)}
        onClose={() => setSettingsOpen(false)}
        variant="temporary"
      >
        <Stack direction="column" divider={<Divider />}>
          <WhatsAppButton />
          <PushNotificationButton />
          <WakeLockButton />
          <VoiceCallButton />
        </Stack>
      </SwipeableDrawer>
    </>
  );
}

export default NotificationDrawer;
