import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from "@mui/material";

function VoiceCallButton() {
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton disabled={true}>
          <ListItemIcon>
            <LocalPhoneIcon />
          </ListItemIcon>
          <ListItemText primary={"Voice call"} secondary="Coming soon" />
          <Switch edge="end" checked={false} />
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default VoiceCallButton;
