import { getToken } from "firebase/messaging";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { messaging, storage, vapidKey } from "./firebaseConfig";

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const requestMessageToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission !== "granted")
    throw new Error(`Permission not granted but ${permission}`);
  let retries = 0;
  let token = "";

  while (true) {
    try {
      token = await getToken(messaging, {
        vapidKey,
      });
      break;
    } catch (e) {
      if (retries > 2) throw e;
      retries++;
      await sleep(300);
    }
  }
  return token;
};

export const uploadFile = async (path: string, file: File) => {
  const fileRef = ref(storage, path);

  await uploadBytes(fileRef, file, { contentType: file.type });
  const extension = file.name.split(".").slice(-1);
  const downloadUrl = await getDownloadURL(fileRef);

  if (process.env.NODE_ENV === "development") return downloadUrl;
  //TODO: ugly solution
  return downloadUrl.replace(`.${extension}`, `_600x600.${extension}`);
};
