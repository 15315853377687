import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Redirect, Route, useLocation, useParams } from "wouter";
import { useSubscribe, useSubscribeOne, useWakeLock } from "../hooks";
import { useUserStore } from "../stores";
import BuzzerCard from "./BuzzerCard";
import ConnectionStatus from "./ConnectionStatus";
import CreateBuzzerForm from "./CreateBuzzerForm";
import MenuBar from "./MenuBar";
import MessageDialog from "./MessageDialog";
import QrDialog from "./QrDialog";
import SenderSettingsDrawer from "./SenderSettingsDrawer";

function Dashboard() {
  const { senderId = "" } = useParams();
  const { wakeLock, wakeLockSupport, enableWakeLock, disableWakeLock } =
    useWakeLock();
  const [sender, _loadingSender, senderError] = useSubscribeOne<Sender>(
    "senders",
    senderId
  );
  const [buzzers, _loadingBuzzer, buzzersError] = useSubscribe<Buzzer>(
    "buzzers",
    [
      ["senderId", "==", senderId],
      ["status", "in", ["ACTIVE", "READY"]],
    ],
    ["createdAt", "desc"]
  );
  const [user, initialUserLoading] = useUserStore((state) => [
    state.user,
    state.initialUserLoading,
  ]);
  const [_, setLocation] = useLocation();

  return (
    <Box textAlign="center" sx={{ mb: 12 }}>
      {!user && !initialUserLoading && <Redirect to="?login=1" />}
      {user?.isAnonymous && (
        <Alert
          severity="warning"
          action={
            <Button color="warning" onClick={() => setLocation("?signup=1")}>
              Sign up
            </Button>
          }
          component={Container}
        >
          Please create a free account so you can come back to your buzzers any
          time.
        </Alert>
      )}
      <Container>
        <MenuBar items={["HOME", "SENDERS", "SETTINGS", "LOGIN"]} />
        <Box sx={{ my: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {sender?.logoUrl ? (
              <img
                src={sender.logoUrl}
                style={{ maxWidth: 300, maxHeight: 150 }}
                alt={sender.name}
              />
            ) : (
              <Typography
                className="gradientText"
                variant="h3"
                fontWeight="800"
                display="inline"
              >
                {sender?.name}
              </Typography>
            )}
          </Box>
          <ConnectionStatus connected={!senderError && !buzzersError} />

          {wakeLockSupport && (
            <FormControlLabel
              sx={{ mb: 2 }}
              control={
                <Switch
                  checked={!!wakeLock}
                  onClick={() =>
                    wakeLock ? disableWakeLock() : enableWakeLock()
                  }
                />
              }
              label="Keep device awake"
            />
          )}

          {!!sender && <CreateBuzzerForm sender={sender} />}
        </Box>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
          marginTop={4}
        >
          {buzzers.map((buzzer) => (
            <BuzzerCard sender={sender} buzzer={buzzer} key={buzzer.id} />
          ))}
        </Stack>

        {buzzers.length === 0 && (
          <Typography variant="h6" sx={{ my: 6 }} color="GrayText">
            Please add a buzzer with the form above.
          </Typography>
        )}

        <Route path="/qr/:buzzerId">
          <QrDialog />
        </Route>
        <Route path="/message/:buzzerId">
          {!!sender && <MessageDialog sender={sender} />}
        </Route>
      </Container>
      {!!sender && <SenderSettingsDrawer sender={sender} />}
    </Box>
  );
}

export default Dashboard;
