import AppleIcon from "@mui/icons-material/Apple";
import IosShareIcon from "@mui/icons-material/IosShare";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../../stores";
import { requestMessageToken } from "../../utils";

function PushNotificationButton() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userProfile, updateUserProfile] = useUserStore(
    useShallow((state) => [state.userProfile, state.updateUserProfile])
  );
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
    // @ts-ignore
    const isInWebAppiOS = window.navigator.standalone === true;
    const isInWebAppChrome = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;

    if (isIos && !isInWebAppChrome && !isInWebAppiOS && !userProfile?.token) {
      setDialogOpen(true);
      return;
    }

    try {
      setLoading(true);
      if (userProfile?.token) {
        await updateUserProfile({ token: "" });
      } else {
        const token = await requestMessageToken();
        token && (await updateUserProfile({ token }));
      }
    } catch (e: any) {
      enqueueSnackbar({
        message: e.message || "Something went wrong",
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setDialogOpen(false);

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <NotificationsNoneIcon />
            )}
          </ListItemIcon>
          <ListItemText
            primary={"Push notifications"}
            secondary="Receive browser push messages when there is an update"
          />
          <Switch
            edge="end"
            checked={!!userProfile?.token}
            disabled={loading}
          />
        </ListItemButton>
      </ListItem>

      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex" }}>
          <AppleIcon sx={{ mr: 1 }} />
          You're using an iPhone
        </DialogTitle>
        <DialogContent>
          To enable push notifications:
          <ol>
            <li>
              <Typography sx={{ display: "flex" }}>
                Click the share button <IosShareIcon sx={{ ml: 1 }} />
              </Typography>
            </li>
            <li>
              Click "<b>Add to Home Screen</b>"
            </li>
            <li>Click the Smart Buzzer icon on your home screen</li>
            <li>Enable push notifications</li>
          </ol>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="gradient" onClick={handleClose}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PushNotificationButton;
