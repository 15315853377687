import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useParams } from "wouter";
import { useCreateDoc, useSubscribeOne } from "../hooks";
import { WithServerFields } from "../stores";
import MessageList from "./MessageList";

interface Props {
  sender: WithServerFields<Sender>;
}

function MessageDialog({ sender }: Props) {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const { buzzerId = "" } = useParams();
  const [location, setLocation] = useLocation();
  const [buzzer] = useSubscribeOne<Buzzer>("buzzers", buzzerId);
  const { create, loading } = useCreateDoc<Message>("messages");

  const handleClose = () => {
    setLocation(`/`);
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!message || !sender?.name) {
      setError(true);
      return;
    }

    await create({
      body: message,
      title: sender?.name,
      buzzerId,
      senderId: sender.id,
      createdBy: sender.createdBy,
    });

    setMessage("");
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle textAlign="center">
        Send an update to {buzzer?.reference}
      </DialogTitle>
      <DialogContent sx={{ minWidth: { xs: 200, sm: 400 } }}>
        <form onSubmit={submitForm}>
          <FormGroup>
            <TextField
              placeholder="Oops, Prepping your order is taking longer than usual. Please bear with us.."
              value={message}
              onChange={(e) => {
                setError(!Boolean(e.target.value));
                setMessage(e.target.value);
              }}
              error={error}
              fullWidth
              multiline
              rows={4}
            />
            <LoadingButton type="submit" loading={loading} variant="gradient">
              Send Message
            </LoadingButton>
          </FormGroup>
        </form>
        <MessageList buzzerId={buzzerId} />
      </DialogContent>
    </Dialog>
  );
}

export default MessageDialog;
