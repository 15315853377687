import CampaignIcon from "@mui/icons-material/Campaign";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import { set } from "lodash";
import React, { useState } from "react";
import { useLocation, useSearch } from "wouter";
import { useUpdateDoc } from "../hooks";
import { WithServerFields } from "../stores";
import { uploadFile } from "../utils";
import ImageField from "./ImageField";

interface Props {
  sender: WithServerFields<Sender>;
}
const SenderSettingsDrawer = ({ sender }: Props) => {
  const [senderSettings, setSenderSettings] = useState(sender);
  const search = useSearch();
  const [location, setLocation] = useLocation();
  const [loading, setLoading] = useState(false);
  const [templateKey, setTemplateKey] = useState("subscribeConfirmation");
  const [logo, setLogo] = useState<File | null>();
  const [background, setBackground] = useState<File | null>();
  const [scImage, setScImage] = useState<File | null>();
  const [ewImage, setEwImage] = useState<File | null>();
  const [orImage, setOrImage] = useState<File | null>();
  const [fuImage, setFuImage] = useState<File | null>();

  const { update } = useUpdateDoc<Sender>("senders", sender.id);

  const handleChange = (key: string, value: string) => {
    const res = set({ ...senderSettings }, key, value);
    setSenderSettings(res);
  };

  const handleClose = () => {
    setLocation(location);
  };
  const handleOpen = () => {
    setLocation("?settings=1");
  };

  const messageTemplates: {
    key: keyof Sender["messageTemplates"];
    label: string;
    handleChange: React.Dispatch<React.SetStateAction<File | null | undefined>>;
    file: File | null | undefined;
  }[] = [
    {
      key: "subscribeConfirmation",
      label: "Subscribe confirmation",
      handleChange: setScImage,
      file: scImage,
    },
    {
      key: "expectedWaitTime",
      label: "Expected wait time",
      handleChange: setEwImage,
      file: ewImage,
    },
    {
      key: "orderReady",
      label: "Order ready",
      handleChange: setOrImage,
      file: orImage,
    },
    {
      key: "followUp",
      label: "Follow up",
      handleChange: setFuImage,
      file: fuImage,
    },
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const data = { ...senderSettings };
    setLoading(true);
    try {
      if (logo) {
        data.logoUrl = await uploadFile(`${sender.id}/${logo.name}`, logo);
      }
      for (const template of messageTemplates) {
        if (!template.file) continue;
        const fileUrl = await uploadFile(
          `${sender.id}/${template.file.name}`,
          template.file
        );
        set(
          senderSettings,
          `messageTemplates.${template.key}.imageUrl`,
          fileUrl
        );
      }

      if (background) {
        data.backgroundUrl = await uploadFile(
          `${sender.id}/${background.name}`,
          background
        );
      }

      await update(data);
    } finally {
      setLoading(false);
    }
    handleClose();
  };

  return (
    <SwipeableDrawer
      open={search.includes("settings=1")}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Box
        sx={{
          m: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">Settings for {sender.name}</Typography>
          <ImageField
            label="Your logo"
            onChange={(file) => setLogo(file)}
            alt={sender.name}
            value={sender.logoUrl}
          />
          <ImageField
            label="Your background"
            onChange={(file) => setBackground(file)}
            alt={sender.name}
            value={sender.backgroundUrl}
          />
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Message templates</Typography>
          {messageTemplates.map((template) => (
            <Accordion
              key={template.key}
              expanded={template.key === templateKey}
              onChange={() => setTemplateKey(template.key)}
            >
              <AccordionSummary>{template.label}</AccordionSummary>
              <AccordionDetails>
                <TextField
                  label={`Text for ${template.label} message`}
                  helperText="use {buzzer.expectedWaitTime} {buzzer.reference} to insert the respective variables. You can also insert links or *bold text*"
                  fullWidth
                  onChange={(e) =>
                    handleChange(
                      `messageTemplates.${templateKey}.body`,
                      e.target.value
                    )
                  }
                  placeholder="All set!"
                  value={senderSettings.messageTemplates[template.key]?.body}
                  InputLabelProps={{ shrink: true }}
                />
                <ImageField
                  label={`Image for ${template.label}`}
                  onChange={(file) => template.handleChange(file)}
                  alt={sender.name}
                  value={
                    senderSettings.messageTemplates[template.key]?.imageUrl
                  }
                />
              </AccordionDetails>
            </Accordion>
          ))}
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Socials</Typography>
          <TextField
            label="Twitter url"
            fullWidth
            onChange={(e) => handleChange("twitter", e.target.value)}
            type="url"
            placeholder="https://twitter.com/your-account"
            value={senderSettings.twitter}
          />
          <TextField
            label="Instagram url"
            fullWidth
            onChange={(e) => handleChange("instagram", e.target.value)}
            type="url"
            placeholder="https://www.instagram.com/your-account"
            value={senderSettings.instagram}
          />
          <TextField
            label="Facebook url"
            fullWidth
            onChange={(e) => handleChange("facebook", e.target.value)}
            placeholder="https://www.facebook.com/your-account"
            value={senderSettings.facebook}
          />
          <TextField
            label="TikTok url"
            fullWidth
            onChange={(e) => handleChange("tiktok", e.target.value)}
            placeholder="https://www.tiktok.com/@your-account"
            value={senderSettings.tiktok}
          />

          <LoadingButton
            size="small"
            variant="gradient"
            type="submit"
            loading={loading}
          >
            Save settings
          </LoadingButton>
        </form>
      </Box>
      <Divider />

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <CampaignIcon />
          </ListItemIcon>
          <ListItemText
            primary={"Send message to previous customers"}
            secondary="Coming soon"
          />
        </ListItemButton>
      </ListItem>
      <Button
        variant="text"
        size="small"
        sx={{ alignSelf: "flex-end", mr: 2 }}
        onClick={handleClose}
      >
        Close settings
      </Button>
    </SwipeableDrawer>
  );
};

export default SenderSettingsDrawer;
