import AndroidIcon from "@mui/icons-material/Android";
import Apple from "@mui/icons-material/Apple";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import BlockIcon from "@mui/icons-material/Block";
import CampaignIcon from "@mui/icons-material/Campaign";
import ChatIcon from "@mui/icons-material/Chat";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StarIcon from "@mui/icons-material/Star";
import WhatsApp from "@mui/icons-material/WhatsApp";
import WindowIcon from "@mui/icons-material/Window";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "wouter";
import CreateSenderForm from "./CreateSenderForm";
import MenuBar from "./MenuBar";

function Landing() {
  const usps = [
    {
      title: "No more physical devices to manage",
      icon: <BlockIcon fontSize="inherit" />,
    },
    {
      title: "Customer can choose notification method",
      icon: <WhatsApp fontSize="inherit" />,
    },
    {
      title: "Engage with your customers",
      icon: <CampaignIcon fontSize="inherit" />,
    },
    {
      title: "Integrate with your PoS software*",
      icon: <PointOfSaleIcon fontSize="inherit" />,
    },
  ];

  const features = [
    {
      title: "Follow-up and engage with your customers",
      secondary: "Ask for reviews or send promotions",
      icon: <StarIcon fontSize="inherit" />,
    },
    {
      title: "Customize your buzzer page",
      secondary: "Add your own logo and ads",
      icon: <AutoFixHighIcon fontSize="inherit" />,
    },
    {
      title: "Keep your customers in the loop",
      secondary: "Send progress updates, or when an order is delayed",
      icon: <ChatIcon fontSize="inherit" />,
    },
    {
      title: "Notify multiple people about the order status",
      secondary: "Any number of people can subscribe to buzzer notifications",
      icon: <PeopleAltIcon fontSize="inherit" />,
    },
    {
      title: "Configure available notification methods",
      secondary: "Push notifications, WhatsApp or phone-as-buzzer",
      icon: <WhatsApp fontSize="inherit" />,
    },
  ];

  return (
    <Box sx={{ textAlign: "center", mb: 12 }}>
      <Container>
        <MenuBar items={["LOGIN", "SENDERS", "SIGNUP"]} />
        <Typography
          className="gradientText"
          variant="h4"
          sx={{ fontWeight: 800, my: 4 }}
        >
          Smart Buzzer
        </Typography>
        <Box sx={{ mt: { xs: 4, md: 8, lg: 16 } }}>
          <Typography variant="h3" fontWeight="bold" sx={{ mb: 4 }}>
            Restaurant buzzers and wait list notifications
            <Divider
              sx={{
                borderColor: "transparent",
                display: { xs: "none", lg: "block" },
              }}
            />{" "}
            on your smartphone
          </Typography>
          <CreateSenderForm />
          <Box sx={{ mb: 6 }}>
            <Typography variant="caption">
              <Link to="/senders/">
                Go to your previously created restaurant page
              </Link>{" "}
              or <Link to="/buzzers/">go to your buzzers</Link> if you're a
              customer
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            No app needed, all phones supported.
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            display="flex"
            justifyContent="center"
            sx={{ mb: 6 }}
          >
            <Apple />
            <AndroidIcon />
            <WindowIcon />
          </Stack>
        </Box>
      </Container>

      <Box sx={{ py: 6, color: "white" }} className="gradient">
        <Container>
          <Typography variant="h4" sx={{ mb: 6 }}>
            Why Smart Buzzer?
          </Typography>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            useFlexGap
            justifyContent="center"
            alignItems="center"
          >
            {usps.map(({ icon, title }, index) => (
              <Box
                display="flex"
                flexDirection="column"
                key={index}
                flex={1}
                sx={{
                  // maxWidth: 300,
                  // minWidth: 300,
                  alignItems: "center",
                }}
              >
                <Box sx={{ fontSize: (t) => t.typography.h3 }}>{icon}</Box>
                <Typography variant="h6">{title}</Typography>
              </Box>
            ))}
          </Stack>
        </Container>
      </Box>
      <Container sx={{ my: 6 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Pricing
        </Typography>
        <Typography variant="h6" gutterBottom>
          You can use our service completely <b>free of charge</b>. We might
          introduce a freemium model in the future.
        </Typography>
        <Divider sx={{ my: 6 }} />
        <Typography variant="h4" sx={{ mb: 2 }}>
          Features
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack sx={{ maxWidth: (t) => t.breakpoints.values.sm }}>
            {features.map(({ title, icon, secondary }, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  my: 4,
                }}
              >
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    mr: 4,
                    fontSize: 30,
                  }}
                  className="gradient"
                >
                  {icon}
                </Avatar>
                <Box>
                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="subtitle1" color="GrayText">
                    {secondary}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default Landing;
