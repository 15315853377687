import { Circle } from "@mui/icons-material";
import { Typography } from "@mui/material";

interface Props {
  connected: boolean;
}

function ConnectionStatus({ connected }: Props) {
  return (
    <Typography
      variant="caption"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {connected ? (
        <>
          <Circle
            fontSize="inherit"
            color="success"
            sx={{
              animation:
                "pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite",
              mr: 1,
            }}
          />{" "}
          connected
        </>
      ) : (
        <>
          <Circle fontSize="inherit" color="error" /> not connected
        </>
      )}
    </Typography>
  );
}

export default ConnectionStatus;
