import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { useLocation } from "wouter";
import { useSubscribe } from "../hooks";
import { useUserStore } from "../stores";
import CreateSenderForm from "./CreateSenderForm";
import MenuBar from "./MenuBar";

function Senders() {
  const [_, setLocation] = useLocation();
  const userProfile = useUserStore((state) => state.userProfile);
  const [senders] = useSubscribe<Sender>("senders", [
    ["createdBy", "==", userProfile?.id],
  ]);

  return (
    <Container sx={{ textAlign: "center", mt: 4 }}>
      <MenuBar items={["HOME", "LOGIN"]} />
      <Typography variant="h6" fontWeight="bold">
        Your buzzers
      </Typography>
      <Stack spacing={2} sx={{ mt: 4 }}>
        {senders.map((sender) => (
          <Card key={sender.id} sx={{ textAlign: "left" }}>
            <CardActionArea
              onClick={() => setLocation(`/dashboard/${sender.id}/`)}
            >
              <CardContent>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  className="gradientText"
                >
                  {sender.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
      <CreateSenderForm label="Create buzzer" />
    </Container>
  );
}

export default memo(Senders);
