import { CheckCircle, Warning } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import Message from "@mui/icons-material/Message";
import QrCode2 from "@mui/icons-material/QrCode2";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Typography,
  colors,
} from "@mui/material";
import { useLocation } from "wouter";
import { dayjs } from "../config";
import { useCreateDoc, useNow, useUpdateDoc } from "../hooks";
import { WithServerFields } from "../stores";
import ExpectedWaitTime from "./ExpectedWaitTime";

interface Props {
  buzzer: WithServerFields<Buzzer>;
  sender?: WithServerFields<Sender>;
}

function BuzzerCard({ buzzer, sender }: Props) {
  const [_, setLocation] = useLocation();
  const { update: updateBuzzer, loading: updatingBuzzer } =
    useUpdateDoc<Buzzer>("buzzers", buzzer.id);
  const { create: createMessage } = useCreateDoc<Message>("messages");
  const now = useNow();

  const notifySubscriber = async () => {
    if (!sender?.name) return;
    await updateBuzzer({ status: "READY" });
    createMessage({
      title: sender.name,
      senderId: sender.id,
      body: sender.messageTemplates.orderReady?.body || "Food is ready!",
      imageUrl: sender.messageTemplates.orderReady?.imageUrl || "",
      buzzerId: buzzer.id,
      important: true,
      createdBy: sender.createdBy,
      type: "orderReady",
    });
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        textAlign: "left",
        background: (t) =>
          buzzer.status === "READY"
            ? colors.green["100"]
            : t.palette.background.paper,
        transition: "all 1s",
      }}
    >
      <CardHeader
        title={buzzer.reference}
        subheader={
          <>
            <Typography gutterBottom>
              created {dayjs(buzzer.createdAt).from(now)}
            </Typography>
            <Box sx={{ display: "flex" }}>
              {buzzer.users.length > 0 ? (
                <CheckCircle
                  fontSize="inherit"
                  color="success"
                  sx={{ mr: 1 }}
                />
              ) : (
                <Warning fontSize="inherit" color="error" sx={{ mr: 1 }} />
              )}
              <Typography color="text.secondary" gutterBottom variant="caption">
                {buzzer.users.length > 0
                  ? "customer enabled notifications"
                  : "customer cannot be notified"}
              </Typography>
            </Box>
          </>
        }
        action={
          <IconButton
            disabled={updatingBuzzer}
            onClick={async (e) => {
              e.stopPropagation();
              await updateBuzzer({ status: "CLOSED" });
            }}
          >
            <Close />
          </IconButton>
        }
      ></CardHeader>
      {sender && (
        <Box sx={{ mx: 2, mb: 2 }}>
          <ExpectedWaitTime buzzer={buzzer} sender={sender} />
        </Box>
      )}
      <CardActions>
        <Button
          size="small"
          variant="outlined"
          onClick={notifySubscriber}
          disabled={updatingBuzzer || buzzer.status === "READY"}
        >
          Notify customer(s)
        </Button>
        <IconButton onClick={() => setLocation(`/qr/${buzzer.id}/`)}>
          <QrCode2 />
        </IconButton>
        <IconButton onClick={() => setLocation(`/message/${buzzer.id}/`)}>
          <Message />
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default BuzzerCard;
