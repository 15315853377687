import type {} from "@mui/lab/themeAugmentation";
import { createTheme, responsiveFontSizes } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    gradient: true;
  }
}

export const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontWeightBold: 800,
      fontFamily: "Work Sans, sans-serif",
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: "contained",
          size: "large",
        },
        variants: [
          {
            props: {
              variant: "gradient",
            },
            style: {
              background: `linear-gradient(45deg, #ff0000 -50%, #0000ff 150%)`,
              color: "white",
              textTransform: "none",
            },
          },
        ],
      },
      MuiTextField: { defaultProps: { margin: "normal" } },
      MuiLoadingButton: {
        defaultProps: { variant: "contained", size: "large" },
      },
    },
    palette: { background: { default: "#eee" }, primary: { main: "#7D0082" } },
  })
);
